// import React from 'react'
// import styled from 'styled-components';

// import Coach1 from "./images/CoachesAndMentors/DBarde.jpg";
// import Coach2 from "./images/CoachesAndMentors/KSalve.jpg";
// import Coach3 from "./images/CoachesAndMentors/PYadav.jpg";

// const Coaches = () => {
//   return (
//     // <div class="container-fluid p-5" id="coaches">
//     //       <div class="text-center">
//     //         <h1 class="text-black display-5 text-uppercase mb-0">
//     //           Our Coaches / Trainers
//     //         </h1>
//     //       </div>

//     //       <div class="tab-class text-center">
//     //         {/* new */}
//     //         <Ys>
//     //           <div>
//     //             {/* <!-- <h1>CARDS</h1> --> */}
//     //             <div className="parent">
//     //               <div className="container">
//     //                 <div
//     //                   className="cards"
//     //                   style={{
//     //                     boxShadow: "9px 10px 14px -8px rgba(0,0,0,0.4)",
//     //                   }}
//     //                 >
//     //                   <div className="card-img">
//     //                     <img src={Coach1} alt="Salman Khan" />
//     //                   </div>
//     //                   <div className="card-body">
//     //                     <h5>DHANANJAY BARDE</h5>
//     //                     <p>
//     //                       EX- VCA PLAYER <br /> ( VCA ACADEMY )
//     //                     </p>
//     //                   </div>
//     //                 </div>
//     //               </div>

//     //               <div className="container">
//     //                 <div
//     //                   className="cards"
//     //                   style={{
//     //                     boxShadow: "9px 10px 14px -8px rgba(0,0,0,0.4)",
//     //                   }}
//     //                 >
//     //                   <div className="card-img">
//     //                     <img src={Coach2} alt="Salman Khan" />
//     //                   </div>
//     //                   <div className="card-body">
//     //                     <h5>KULDEEP SALVE</h5>
//     //                     <p>
//     //                       EX- VCA PLAYER <br /> ( OMAN A TEAM )
//     //                     </p>
//     //                   </div>
//     //                 </div>
//     //               </div>

//     //               <div className="container">
//     //                 <div
//     //                   className="cards"
//     //                   style={{
//     //                     boxShadow: "9px 10px 14px -8px rgba(0,0,0,0.4)",
//     //                   }}
//     //                 >
//     //                   <div className="card-img">
//     //                     <img src={Coach3} alt="Salman Khan" />
//     //                   </div>
//     //                   <div className="card-body">
//     //                     <h5>PRADEEP KUMAR YADAV</h5>
//     //                     <p
//     //                       className="text-primar card-text"
//     //                       style={{ color: " rgb(80 80 80)" }}
//     //                     >
//     //                       ICC LEVEL 1 <br />( COACH )
//     //                     </p>
//     //                   </div>
//     //                 </div>
//     //               </div>
//     //             </div>
//     //           </div>
//     //         </Ys>

//     //         <div class="tab-content">
//     //           <div id="tab-1" class="tab-pane fade show p-0 active">
//     //             <div
//     //               class="row g-3"
//     //               style={{ display: "flex", justifyContent: "center" }}
//     //             ></div>
//     //           </div>
//     //         </div>
//     //       </div>
//     //     </div>
//     <>
//         <Ys>
//         <div class="container text-center py-5">
//         <h3 >Meet Our Expert Coaches</h3>
//         <br />
//         <p class="text-muted" style={{fontWeight:'500'}}>At Sahas Cricket Club, we pride ourselves on having a team of highly skilled and experienced coaches dedicated to helping you reach your full potential. Our coaches are passionate about guiding individuals towards their goals, whether it's personal development, career advancement, or enhancing specific skills</p>
//         <div class="row row-cols-1 row-cols-md-3 g-4 ">
//             <div class="col">
//               <div class="card">
//                 <img src={Coach1} class="card-img-top" alt="..."/>
//                 <div class="card-body">
//                   <h5 class="card-title">DHANANJAY BARDE</h5>
//                   <p class="card-text">EX- VCA PLAYER ( VCA ACADEMY )</p>
//                 </div>
//                 {/* <div class="d-flex justify-content-evenly p-4">
//                     <i class="bi bi-facebook"></i>
//                     <i class="bi bi-linkedin"></i>
//                     <i class="bi bi-envelope-fill"></i>
//                     <i class="bi bi-whatsapp"></i>
//                 </div> */}
//               </div>
//             </div>
//             <div class="col">
//               <div class="card">
//                 <img src={Coach2} class="card-img-top" alt="..."/>
//                 <div class="card-body">
//                   <h5 class="card-title">KULDEEP SALVE</h5>
//                   <p class="card-text">EX- VCA PLAYER ( OMAN A TEAM )</p>
//                 </div>
//                 {/* <div class="d-flex justify-content-evenly p-4 link">
//                     <i class="bi bi-facebook"></i>
//                     <i class="bi bi-linkedin"></i>
//                     <i class="bi bi-envelope-fill"></i>
//                     <i class="bi bi-whatsapp"></i>
//                 </div> */}
//               </div>
//             </div>
//             <div class="col">
//               <div class="card">
//                 <img src={Coach3} class="card-img-top" alt="..."/>
//                 <div class="card-body">
//                   <h5 class="card-title">PRADEEP KUMAR YADAV</h5>
//                   <p class="card-text">ICC LEVEL 1 ( COACH )</p>
//                 </div>
//                 {/* <div class="d-flex justify-content-evenly p-4">
//                     <i class="bi bi-facebook"></i>
//                     <i class="bi bi-linkedin"></i>
//                     <i class="bi bi-envelope-fill"></i>
//                     <i class="bi bi-whatsapp"></i>
//                 </div> */}
//               </div>
//             </div>
         
//           </div>
//     </div>
//     </Ys>
//     </>
//   )
// }

// export default Coaches



// const Ys = styled.div`

//     padding: 0;
//     margin: 0;
//     box-sizing: border-box;

// body{
//     background-color: aliceblue;
// }

// .card-img-top{
//     /* border-radius: 50%; */
//     height: 400px !important;
//     width: 100% !important;
//     /* position: relative;
//     left: 80px;
//     top: -80px; */
//     object-fit: cover;
// }
// .card{
//     border-radius: 0;
//     cursor: pointer;
//     margin-top: 50px !important;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     flex-direction: column;
//     gap: 50px;

//     padding: 20px;
// }
// .card:hover{
//     box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;

// }
// .card-body{
//     margin-top: -50px;
// }
// .bi{
//     font-size: 25px;
// }
// @media only screen and (max-width: 600px) {
//     .card-img-top{
        
//         left: 48px !important;
       
//     }
//   }
// `;

import React from 'react';
import styled from 'styled-components';
import Coach1 from "./images/CoachesAndMentors/DBarde.jpg";
import Coach2 from "./images/CoachesAndMentors/KSalve.jpg";
import Coach3 from "./images/CoachesAndMentors/PYadav.jpg";

import 'aos/dist/aos.css'
import AOS from 'aos'
import PlayerCard from './PlayerCard';
import PlayerSwipper from './PlayerSwipper';
import { Link } from 'react-router-dom';

const Coaches = () => {
  AOS.init({
    duration: 650,
    once: false
  });
  return (
    <CoachesContainer>
      <div className='container' style={{height:'60px', margin:'0 1px',marginLeft:'auto',marginRight:'auto', display:'flex', alignItems:'start'}}>
                      <div className="col-md-9 ftco-animate" style={{display:'flex', position:'relative', alignItems:'start'}}>
                        <div className="">
                        <span className="" style={{display:'flex', background:'#f1f1f1', padding: '1px 20px 7px 20px', alignItems:'center'}}>
                          <span className="mr-2">
                            <Link to={'/'}>
                              <span>Home <i className="fa fa-chevron-right"></i></span>
                            </Link>
                          </span>{" "}
                          <span className='a'>
                            Trainers <i className="fa fa-chevron-right"></i>
                          </span>
                        </span>
                        </div>
                      </div>
          </div>


      <div className="container text-center py-5">
        <h3>Meet Our Expert Coaches</h3>
        <p className="text-muted" style={{ fontWeight: '500' }}>
          At Sahas Cricket Club, we pride ourselves on having a team of highly skilled and experienced coaches dedicated to helping you reach your full potential. Our coaches are passionate about guiding individuals towards their goals, whether it's personal development, career advancement, or enhancing specific skills
        </p>
        <div className="row row-cols-1 row-cols-md-3 g-4">
          <div className="col">
            <div className="card" data-aos="zoom-in">
              <img src={Coach1} className="card-img-top" alt="Coach 1" />
              <div className="card-body">
                <h5 className="card-title">DHANANJAY BARDE</h5>
                <p className="card-text">EX- VCA PLAYER (VCA ACADEMY)</p>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card" data-aos="zoom-in">
              <img src={Coach2} className="card-img-top" alt="Coach 2" />
              <div className="card-body">
                <h5 className="card-title">KULDEEP SALVE</h5>
                <p className="card-text">EX- VCA PLAYER (OMAN A TEAM)</p>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card" data-aos="zoom-in">
              <img src={Coach3} className="card-img-top" alt="Coach 3" />
              <div className="card-body">
                <h5 className="card-title">PRADEEP KUMAR YADAV</h5>
                <p className="card-text">ICC LEVEL 1 (COACH)</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <PlayerSwipper/> */}
    </CoachesContainer>
  )
}

const CoachesContainer = styled.div`
  background-color: #f8f9fa;

  .card {
    border-radius: 10px;
    cursor: pointer;
    margin-top: 30px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  }

  .card:hover {
    box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.1);
  }

  .card-img-top {
    border-radius: 10px 10px 0 0;
    object-fit: cover;
    height: 500px;
  }

  .card-body {
    padding: 20px;
  }
`;

export default Coaches;
